import React, { useState, useCallback, useContext } from "react";
import Sidebar from "../../components/Sidebar";

import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  FormButtons,
  VariantsTitle,
  Variant,
  AddVariantIcon,
} from "./productinput";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const ProductInput = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    type: "regular",
    productName: "",
    duration: "",
    variants: [
      {
        pricingType: "person",
        priceAdult: 0,
        priceHalf: 0,
        priceNet: 0,
        priceGroup: 0,
        paxLimit: 0,
      },
    ],
  });
  const [formsStatus, setFormsStatus] = useState({});

  const { userName, userPermissions } = useContext(StoreContext);

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      if (e.target.name === "currency")
        newFormData.comissionCurrency = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const onChangeVariant = useCallback((e, index) => {
    let newFormData = formData;
    newFormData.variants[index][e.target.name] = e.target.value;
    setFormData(newFormData);
    setFormsStatus(Math.random());
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;

      fetch(`${API_URL}products/create.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucesso</p>,
              html: <i>Produto cadastrado com sucesso</i>,
              icon: "success",
            }).then(() => {
              return e.nativeEvent.submitter.getAttribute("create-new")
                ? navigate(0)
                : navigate("/listar-produtos");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Algo deu errado!",
            });
          }
        });
    },
    [formData, userPermissions]
  );

  const newVariant = useCallback(() => {
    formData.variants.push({
      pricingType: "person",
      priceAdult: 0,
      priceHalf: 0,
      priceNet: 0,
      priceGroup: 0,
      paxLimit: 0,
    });
    setFormsStatus(Math.random());
  }, [formData]);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Produto</SubTitle>
        <Title>Cadastrar</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="tour-type-label">Tipo</InputLabel>
              <Select
                labelId="tour-type-label"
                id="tour-type"
                name="type"
                label="Tipo"
                value={formData.type}
                onChange={onchange}
              >
                <MenuItem value="regular">Regular</MenuItem>
                <MenuItem value="privativo">Privativo</MenuItem>
                <MenuItem value="show/evento">Show/Evento</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="productName"
              label="Nome do Produto"
              variant="outlined"
              name="productName"
              onChange={onchange}
              value={formData.productName}
            />
            <TextField
              id="duration"
              label="Duração"
              variant="outlined"
              name="duration"
              onChange={onchange}
              value={formData.duration}
            />
          </FormRow>
          {formData.type === "show/evento" ? (
            <FormRow>
              <TextField
                id="priceAdult"
                label="Preço (R$)"
                variant="outlined"
                name="priceAdult"
                onChange={(e) => onChangeVariant(e, 0)}
                value={formData.variants[0].priceAdult}
                type="number"
              />
              <div></div>
            </FormRow>
          ) : (
            <>
              <VariantsTitle>Variantes</VariantsTitle>
              {formData.variants.map((variant, index) =>
                formData.type === "regular" ? (
                  <Variant key={`variant-regular-${index}`}>
                    <FormRow>
                      <FormControl fullWidth>
                        <InputLabel id="pricing-type-label">
                          Tipo de Precificação
                        </InputLabel>
                        <Select
                          labelId="pricing-type-label"
                          id="pricingType"
                          name="pricingType"
                          label="Tipo de Precificação"
                          value={variant.pricingType}
                          onChange={(e) => onChangeVariant(e, index)}
                        >
                          <MenuItem value="group">Por Grupo</MenuItem>
                          <MenuItem value="person">Por Pessoa</MenuItem>
                        </Select>
                      </FormControl>
                      {variant.pricingType === "person" ? (
                        <TextField
                          id="priceAdult"
                          label="Preço Adulto (R$)"
                          variant="outlined"
                          name="priceAdult"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceAdult}
                          type="number"
                        />
                      ) : (
                        <TextField
                          id="priceGroup"
                          label="Preço Grupo (R$)"
                          variant="outlined"
                          name="priceGroup"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceGroup}
                          type="number"
                        />
                      )}
                    </FormRow>
                    {variant.pricingType === "person" && (
                      <FormRow>
                        <TextField
                          id="priceHalf"
                          label="Preço Meia Entrada"
                          variant="outlined"
                          name="priceHalf"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceHalf}
                          type="number"
                        />
                        <TextField
                          id="priceNet"
                          label="Preço NET"
                          variant="outlined"
                          name="priceNet"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceNet}
                          type="number"
                        />
                      </FormRow>
                    )}
                    <FormRow>
                      <TextField
                        id="paxLimit"
                        label="Mínimo de Pessoas"
                        variant="outlined"
                        name="paxLimit"
                        onChange={(e) => onChangeVariant(e, index)}
                        value={variant.paxLimit}
                        type="number"
                        InputProps={{
                          readOnly: index === 0,
                        }}
                      />
                      <div></div>
                    </FormRow>
                  </Variant>
                ) : (
                  <Variant key={`variant-privativo-${index}`}>
                    <FormRow>
                      <FormControl fullWidth>
                        <InputLabel id="pricing-type-label">
                          Tipo de Precificação
                        </InputLabel>
                        <Select
                          labelId="pricing-type-label"
                          id="pricingType"
                          name="pricingType"
                          label="Tipo de Precificação"
                          value={variant.pricingType}
                          onChange={(e) => onChangeVariant(e, index)}
                        >
                          <MenuItem value="group">Por Grupo</MenuItem>
                          <MenuItem value="person">Por Pessoa</MenuItem>
                        </Select>
                      </FormControl>
                      {variant.pricingType === "person" ? (
                        <TextField
                          id="priceAdult"
                          label="Preço por Pessoa (R$)"
                          variant="outlined"
                          name="priceAdult"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceAdult}
                          type="number"
                        />
                      ) : (
                        <TextField
                          id="priceGroup"
                          label="Preço Grupo (R$)"
                          variant="outlined"
                          name="priceGroup"
                          onChange={(e) => onChangeVariant(e, index)}
                          value={variant.priceGroup}
                          type="number"
                        />
                      )}
                    </FormRow>

                    <FormRow>
                      <TextField
                        id="paxLimit"
                        label="Mínimo de Pessoas"
                        variant="outlined"
                        name="paxLimit"
                        onChange={(e) => onChangeVariant(e, index)}
                        value={variant.paxLimit}
                        type="number"
                        InputProps={{
                          readOnly: index === 0,
                        }}
                      />
                      <div></div>
                    </FormRow>
                  </Variant>
                )
              )}
              <AddVariantIcon onClick={newVariant} />
            </>
          )}
          <FormButtons>
            <Button variant="outlined" type="submit">
              Salvar
            </Button>
            <Button variant="outlined" type="submit" create-new="true">
              Salvar e Criar Outra
            </Button>
          </FormButtons>
        </FormBox>
      </Content>
    </Main>
  );
};

export default ProductInput;
